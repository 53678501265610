// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExpandedIntegrationRouteDisplay-module__M1BObcDlJInv2bOuotVp{width:500px}.ExpandedIntegrationRouteDisplay-module__VNhRBB8c3FMtSKMlM3xW{width:700px}.ExpandedIntegrationRouteDisplay-module__aZLLbIM9ob8zwQc8ZuDh{display:flex;flex-direction:column;width:200px;border-radius:2px}.ExpandedIntegrationRouteDisplay-module__kmdjI8mQDM5z_948kRg6{padding:8px;display:flex;align-items:center;flex-direction:row;flex-shrink:0;white-space:nowrap;border-left:2px solid rgba(0,0,0,0);cursor:pointer;min-width:84px;display:flex;gap:8px;flex-direction:row}.ExpandedIntegrationRouteDisplay-module__kmdjI8mQDM5z_948kRg6:hover{background:var(--gray-9)}", "",{"version":3,"sources":["webpack://./containers/IntegrationContainers/ExpandedIntegrationRouteDisplay/ExpandedIntegrationRouteDisplay.module.scss"],"names":[],"mappings":"AACE,8DACE,WAAA,CAEF,8DACE,WAAA,CAIJ,8DACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CAGF,8DACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,kBAAA,CACA,mCAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,oEACE,wBAAA","sourcesContent":[".input {\n  &--short {\n    width: 500px;\n  }\n  &--long {\n    width: 700px;\n  }\n}\n\n.integrations-actionsList {\n  display: flex;\n  flex-direction: column;\n  width: 200px;\n  border-radius: 2px;\n}\n\n.integrations-actionItem {\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  flex-shrink: 0;\n  white-space: nowrap;\n  border-left: 2px solid transparent;\n  cursor: pointer;\n  min-width: 84px;\n  display: flex;\n  gap: 8px;\n  flex-direction: row;\n\n  &:hover {\n    background: var(--gray-9);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input--short": "ExpandedIntegrationRouteDisplay-module__M1BObcDlJInv2bOuotVp",
	"input--long": "ExpandedIntegrationRouteDisplay-module__VNhRBB8c3FMtSKMlM3xW",
	"integrations-actionsList": "ExpandedIntegrationRouteDisplay-module__aZLLbIM9ob8zwQc8ZuDh",
	"integrations-actionItem": "ExpandedIntegrationRouteDisplay-module__kmdjI8mQDM5z_948kRg6"
};
export default ___CSS_LOADER_EXPORT___;
