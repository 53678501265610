// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationBlock-module__F3zxdDNkFmLXxLg0fDeA,.IntegrationBlock-module__mTvYGbkrqUqIJpqi8dE0{padding:16px}.IntegrationBlock-module__F3zxdDNkFmLXxLg0fDeA{background-color:var(--background-secondary);border:none}.IntegrationBlock-module__mTvYGbkrqUqIJpqi8dE0{background:var(--background-primary);border:var(--border-weak);padding-bottom:0}.IntegrationBlock-module__Jl7Vj7yq0FVPLv9pTrbc{border-left:none;padding-left:0;padding-right:0}", "",{"version":3,"sources":["webpack://./components/Integrations/IntegrationBlock.module.scss"],"names":[],"mappings":"AAAA,8FAEE,YAAA,CAGF,+CACE,4CAAA,CACA,WAAA,CAGF,+CACE,oCAAA,CACA,yBAAA,CACA,gBAAA,CAEA,+CACE,gBAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".integrationBlock__heading,\n.integrationBlock__content {\n  padding: 16px;\n}\n\n.integrationBlock__heading {\n  background-color: var(--background-secondary);\n  border: none;\n}\n\n.integrationBlock__content {\n  background: var(--background-primary);\n  border: var(--border-weak);\n  padding-bottom: 0;\n\n  &--collapsedBorder {\n    border-left: none;\n    padding-left: 0;\n    padding-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integrationBlock__heading": "IntegrationBlock-module__F3zxdDNkFmLXxLg0fDeA",
	"integrationBlock__content": "IntegrationBlock-module__mTvYGbkrqUqIJpqi8dE0",
	"integrationBlock__content--collapsedBorder": "IntegrationBlock-module__Jl7Vj7yq0FVPLv9pTrbc"
};
export default ___CSS_LOADER_EXPORT___;
