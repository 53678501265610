// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Integrations2-module__ra_wYRxE4y4No1JLtCJi{width:180px}.Integrations2-module__a7V5VJj2ldw0i8sbD1Dh{margin-bottom:24px;right:0}.Integrations2-module__j1Fmeuw3RGN900Irvfiw{height:40px}.Integrations2-module__fOSApz7_BYayaHgHi32c{margin-top:16px}.Integrations2-module__hOZ8iYLlTh2OeErdLr0g{padding:4px 10px;width:40px}.Integrations2-module__weRrANrNiF4uYKK7KJK0{display:flex;flex-direction:column;width:200px;border-radius:2px}.Integrations2-module__AsEtLVE1Uds7QmnFRagg{padding:8px;display:flex;align-items:center;flex-direction:row;flex-shrink:0;white-space:nowrap;border-left:2px solid rgba(0,0,0,0);cursor:pointer;min-width:84px;display:flex;gap:8px;flex-direction:row}.Integrations2-module__AsEtLVE1Uds7QmnFRagg:hover{background:var(--gray-9)}", "",{"version":3,"sources":["webpack://./pages/integrations_2/Integrations2.module.scss"],"names":[],"mappings":"AAAA,4CACE,WAAA,CAGF,4CACE,kBAAA,CACA,OAAA,CAGF,4CACE,WAAA,CAGF,4CACE,eAAA,CAGF,4CACE,gBAAA,CACA,UAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CAGF,4CACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,kBAAA,CACA,mCAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,kDACE,wBAAA","sourcesContent":[".newIntegrationButton {\n  width: 180px;\n}\n\n.title {\n  margin-bottom: 24px;\n  right: 0;\n}\n\n.integrations-table-row {\n  height: 40px;\n}\n\n.integrations-table {\n  margin-top: 16px;\n}\n\n.heartbeat-badge {\n  padding: 4px 10px;\n  width: 40px;\n}\n\n.integrations-actionsList {\n  display: flex;\n  flex-direction: column;\n  width: 200px;\n  border-radius: 2px;\n}\n\n.integrations-actionItem {\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  flex-shrink: 0;\n  white-space: nowrap;\n  border-left: 2px solid transparent;\n  cursor: pointer;\n  min-width: 84px;\n  display: flex;\n  gap: 8px;\n  flex-direction: row;\n\n  &:hover {\n    background: var(--gray-9);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newIntegrationButton": "Integrations2-module__ra_wYRxE4y4No1JLtCJi",
	"title": "Integrations2-module__a7V5VJj2ldw0i8sbD1Dh",
	"integrations-table-row": "Integrations2-module__j1Fmeuw3RGN900Irvfiw",
	"integrations-table": "Integrations2-module__fOSApz7_BYayaHgHi32c",
	"heartbeat-badge": "Integrations2-module__hOZ8iYLlTh2OeErdLr0g",
	"integrations-actionsList": "Integrations2-module__weRrANrNiF4uYKK7KJK0",
	"integrations-actionItem": "Integrations2-module__AsEtLVE1Uds7QmnFRagg"
};
export default ___CSS_LOADER_EXPORT___;
